import liff from '@line/liff/dist/lib';
import axios, { AxiosRequestHeaders } from 'axios';
import {
  BASE_URL,
  ONLINE_CHARGE_TRANSITION_PAGE_PATH,
  SESSION_EXPIRED_PAGE_PATH
} from 'lib/constants';
import {
  RegisterUserType,
  BasicUserDataType,
  CardReissueType
} from 'types/UserType';

interface RequestType {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  hasAccessToken?: boolean;
  data?: object;
  params?: object;
  isErrorNavigate?: boolean;
}

const sendRequest = ({
  url,
  method,
  hasAccessToken,
  data,
  params,
  isErrorNavigate = true
}: RequestType) => {
  const idToken = liff.getIDToken() as string;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
    'Token-Id': idToken
  };

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken() as string;
    headers['Access-Token'] = accessToken;
  }

  return axios({
    url: BASE_URL + url,
    method: method,
    headers: headers,
    data,
    params: params
  })
    .then((res) => res.data)
    .catch((err) => {
      const error = err.response;

      if (error && isErrorNavigate) {
        // Unauthorizedエラー: セッション切れ
        // NOTE: 404の場合、errorsが含まれない可能性があるのでoptionalを含める
        // NOTE: CSDを利用するAPIが503でエラーした場合、dataが含まれない可能性があるのでoptionalを含める
        if (error.data?.errors?.includes('IdToken expired.')) {
          localStorage.clear();
          // 外部のクレジットカードチャージサイトへの移動確認画面の場合、自動で画面遷移させたいのでURLは変更せずリロードする
          // 後続の 未登録のユーザー のエラー処理が実行されないように return する
          return window.location.pathname === ONLINE_CHARGE_TRANSITION_PAGE_PATH
            ? setTimeout(() => window.location.reload(), 300)
            : window.location.replace(
                `${process.env.PUBLIC_URL}${SESSION_EXPIRED_PAGE_PATH}`
              );
        }
        // Unauthorizedエラー: 未登録のユーザー
        if (error.status === 401) {
          setTimeout(
            () =>
              window.location.pathname !== '/' &&
              window.location.replace(process.env.PUBLIC_URL + '/'),
            250
          );
        }
        // VDエラー（連携先サーバーのエラー）以外のサーバーエラーは、サーバーエラー画面へ
        // * VDエラーの場合、以下のエラーコードが返ってくる
        // https://docs.google.com/spreadsheets/d/1Xov7Fp-qDGX7FDHfkPvAQX9z1B90omRLrPSIn5Pdhxs/edit#gid=1054226400
        if (error.status === 500 && !error.data.error_code) {
          window.location.replace(process.env.PUBLIC_URL + '/error/500');
        }
        return { ...error.data, http_status: error.status };
      } else {
        return false;
      }
    });
};

type ErrorResponse =
  | {
      status: 'failed';
      http_status: number;
      errors: string[];
      error_code: string;
    }
  | false;

/** API002 ユーザー情報取得 */
export const getCurrentUser = () => {
  return sendRequest({
    url: '/users/current',
    method: 'GET'
  });
};

/** API003 ユーザー情報編集 */
export const updateCurrentUser = (data?: BasicUserDataType) => {
  return sendRequest({
    url: '/users/current',
    method: 'PATCH',
    data
  });
};

/** API001 会員登録 */
export const registerUser = (data?: RegisterUserType) => {
  return sendRequest({
    url: '/users',
    method: 'POST',
    hasAccessToken: true,
    data
  });
};

/** API022 ブランド一覧取得 */
export const getBrandList = () => {
  return sendRequest({
    url: '/brands',
    method: 'GET'
  });
};

/** API016 ストア一覧取得 */
export const getStoreList = (brandId?: number) => {
  return sendRequest({
    url: '/stores',
    method: 'GET',
    params: {
      brand_id: brandId || undefined
    }
  });
};

/** API019 マイストア一覧取得 */
export const getMyStoreList = () => {
  return sendRequest({
    url: '/my_stores',
    method: 'GET'
  });
};

/** API020 対象のストアをマイストアに登録 */
export const registerMyStore = (storeId: number) => {
  return sendRequest({
    url: `/my_stores/${storeId}`,
    method: 'POST'
  });
};

/** API021 対象のストアをマイストアから削除 */
export const deleteMyStore = (storeId: number) => {
  return sendRequest({
    url: `/my_stores/${storeId}`,
    method: 'DELETE'
  });
};

/** API017 ストア詳細取得 */
export const getStoreInfo = (storeId: number) => {
  return sendRequest({
    url: `/stores/${storeId}`,
    method: 'GET'
  });
};

/** API018 ストアのお知らせ取得 */
export const getStoreNews = (storeId: number) => {
  return sendRequest({
    url: `/stores/${storeId}/notifications`,
    method: 'GET'
  });
};

/** API004 カード再発行/再連携 */
export const reissueCard = (data: CardReissueType) => {
  return sendRequest({
    url: '/users/current/cards',
    method: 'POST',
    hasAccessToken: true,
    data
  });
};

/** API008 クーポン一覧情報の取得 */
export const getCouponList = (myStore: boolean, brandId?: number) => {
  return sendRequest({
    url: '/coupons',
    method: 'GET',
    params: {
      my_store: myStore || undefined,
      brand_id: brandId || undefined
    }
  });
};

/** API009 クーポンの詳細情報取得 */
export const getCouponDetail = (couponId: number) => {
  return sendRequest({
    url: `/coupons/${couponId}`,
    method: 'GET'
  });
};

/** API010 利用したクーポンの履歴情報取得 */
export const issueCoupon = (couponId: number) => {
  return sendRequest({
    url: `/coupons/${couponId}/histories`,
    method: 'POST'
  });
};

/** API005 CS-Dからポイント残高取得 */
export const getCurrentPoints = () => {
  return sendRequest({
    url: '/users/current/point/balance',
    method: 'GET'
  });
};

/** API006 プリペイド残高取得 */
export const getCurrentPrepaid = () => {
  return sendRequest({
    url: '/users/current/prepaid/balance',
    method: 'GET'
  });
};

/** API014 お知らせ一覧情報の取得 */
export const getNewsList = (brandId?: number) => {
  return sendRequest({
    url: '/notifications',
    method: 'GET',
    params: {
      brand_id: brandId || undefined
    }
  });
};

/** API015 お知らせの詳細情報取得 */
export const getNewsDetails = (newsId: number) => {
  return sendRequest({
    url: `/notifications/${newsId}`,
    method: 'GET'
  });
};

/** API007 プリペイド履歴取得 */
export const getCardHistory = ({
  limit,
  offset
}: {
  limit: number;
  offset: number;
}) => {
  return sendRequest({
    url: '/users/current/prepaid/histories',
    method: 'GET',
    params: {
      limit: limit,
      offset: offset
    }
  });
};

/** API023 カード番号チェック */
export const verifyCardExist = (cardNo: string) => {
  return sendRequest({
    url: `/card_availability/${cardNo}`,
    method: 'GET'
  });
};

/** API024 お知らせの未読数取得 */
type CurrentNotificationsUnreadResponse = {
  status: 'succeeded';
  data: { unread_count: number };
};
export const getCurrentNotificationsUnread = (): Promise<
  CurrentNotificationsUnreadResponse | ErrorResponse
> => {
  return sendRequest({
    url: '/users/current/notifications/unread',
    method: 'GET'
  });
};

/** API025 お知らせを既読更新 */
type PostNotificationsResponse = { status: 'succeeded'; data: { id: number } };
export const postNotifications = (
  notificationId: number
): Promise<PostNotificationsResponse | ErrorResponse> => {
  return sendRequest({
    url: '/notifications',
    params: {
      notification_id: notificationId
    },
    method: 'POST'
  });
};

/** API028 デジクルプラスURL取得 */
type PostDigiclueplusResponse = {
  status: 'succeeded';
  data: { service_url: string };
};
export const postDigiclueplus = (
  serviceCode: 'point_coupons' | 'stamps' // いまのところポイントクーポンとキャンペーンのみ
): Promise<PostDigiclueplusResponse | ErrorResponse> => {
  return sendRequest({
    url: '/users/current/digiclueplus',
    params: { service_code: serviceCode },
    method: 'POST'
  });
};

/** API026 スマレシ会員照会 */
type GetSmartReceiptResponse = {
  status: 'succeeded';
  data: { linked: boolean };
};
export const getSmartReceipt = (): Promise<
  GetSmartReceiptResponse | ErrorResponse
> => {
  return sendRequest({
    url: '/users/current/smart_receipt',
    method: 'GET',
    // NOTE: スマレシ機能が使えない場合は使えない旨をホームに表示したいため、エラー時の遷移をしないように制御
    isErrorNavigate: false
  });
};

/** API027 スマレシ会員連携 */
type PostSmartReceiptLinkResponse = {
  status: 'succeeded';
  data: { linked: boolean };
};
export const postSmartReceiptLink = (
  smartReceiptId: string,
  smartReceiptPassword: string
): Promise<PostSmartReceiptLinkResponse | ErrorResponse> => {
  return sendRequest({
    url: '/users/current/smart_receipt/link',
    data: {
      smart_receipt_id: smartReceiptId,
      smart_receipt_password: smartReceiptPassword
    },
    method: 'POST'
  });
};

type PatchPrepaidChargeResponse = {
  status: 'succeeded';
  data: { deposit_amount: number };
};

/** API029 プリペイドチャージ確認 */
export const patchPrepaidChargeConfirm = (
  amount: number
): Promise<PatchPrepaidChargeResponse | ErrorResponse> =>
  sendRequest({
    url: '/users/current/prepaid/confirm_charge',
    data: {
      amount
    },
    method: 'PATCH'
  });

/** API030 プリペイドチャージ */
export const patchPrepaidCharge = (
  amount: number
): Promise<PatchPrepaidChargeResponse | ErrorResponse> =>
  sendRequest({
    url: '/users/current/prepaid/charge',
    data: {
      amount
    },
    method: 'PATCH'
  });
