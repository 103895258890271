import liff from '@line/liff/dist/lib';
import axios, { AxiosRequestHeaders } from 'axios';
import { BASE_URL, SESSION_EXPIRED_PAGE_PATH } from 'lib/constants';

interface RequestType {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  hasAccessToken: boolean;
  data?: object;
  params?: object;
}

type ErrorResponse =
  | {
      status: 'failed';
      errors: string[];
      errorCode: string;
      statusCode?: number;
    }
  | false;

const sendRequest = ({
  url,
  method,
  hasAccessToken,
  data,
  params
}: RequestType) => {
  const idToken = liff.getIDToken() as string;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
    'Token-Id': idToken
  };

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken() as string;
    headers['Access-Token'] = accessToken;
  }

  return axios({
    url: BASE_URL + url,
    method,
    headers,
    data,
    params
  })
    .then((res) => res.data)
    .catch((err) => {
      if (!err.response) {
        return false;
      }
      const error = err.response;

      if (error) {
        // セッション切れ
        if (error.data.errors.includes('IdToken expired.')) {
          window.location.replace(
            `${process.env.PUBLIC_URL}${SESSION_EXPIRED_PAGE_PATH}`
          );
        }
        // 500エラー
        if (error.status === 500 && !error.data.errorCode) {
          window.location.replace(process.env.PUBLIC_URL + '/error/500');
        }
        return {
          ...error.data,
          statusCode: error.status
        };
      }
      return false;
    });
};

type PostDigiclueplusTokenResponse = {
  status: 'succeeded';
  data: {
    service_url: string;
  };
};

export const postDigiclueplusToken = (
  serviceCode: string
): Promise<PostDigiclueplusTokenResponse | ErrorResponse> => {
  return sendRequest({
    url: '/users/current/digiclueplus',
    params: { service_code: serviceCode },
    method: 'POST',
    hasAccessToken: false
  });
};
