import { postDigiclueplusToken } from 'api/digiclueplus';
import PageTitle from 'component/PageTitle';
import MainLayout from 'layout/MainLayout';
import { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { openWindow } from 'lib';
import Loader from 'component/Loader';

const ErrorContainer: FC<{ message: string; buttonText: string }> = ({
  message,
  buttonText
}) => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Row className="m-1">
        <Col md={7} className="mx-auto">
          <h3>{message}</h3>
          <Stack className="fixed-bottom p-4">
            <Row>
              <Col md={7} className="mx-auto">
                <Button
                  variant="-primary"
                  className="btn--custom p-3 py-3 w-100"
                  onClick={() => navigate('/')}
                >
                  {buttonText}
                </Button>
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </MainLayout>
  );
};

const Loading: FC = () => (
  <MainLayout>
    <Row className="mt-4">
      <Col md={7} className="mx-auto">
        <Loader />
      </Col>
    </Row>
  </MainLayout>
);

const RedirectDigiclueplus: FC = () => {
  PageTitle('マキヤミニアプリ');

  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [hasError, setHasError] = useState(false);
  const ignoreEffect = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const digiclueplus = searchParams.get('digiclueplus');
  const wallPath = searchParams.get('wall_path')?.slice(1); // 先頭のスラッシュを削除

  // パラメータのバリデーションを先に行い、エラーJSXを早期返却
  if (!digiclueplus || !wallPath) {
    return (
      <ErrorContainer
        message="エラーが発生しました。もう一度お試しください。"
        buttonText="ホームに戻る"
      />
    );
  }

  useEffect(() => {
    if (ignoreEffect.current || hasError) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setHasError(true);
    }, 10000);

    (async () => {
      const tokenResponse = await postDigiclueplusToken(wallPath);

      // API読み込み完了したらタイムアウトを解除
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      ignoreEffect.current = true;

      if (tokenResponse === false) {
        setHasError(true);
        return;
      }

      // エラー時の処理
      if (tokenResponse.status === 'failed') {
        if (tokenResponse.statusCode === 401) {
          setIsUnauthorized(true);
          return;
        }
        setHasError(true);
        return;
      }

      // Responseが足りない
      if (!tokenResponse.data.service_url) {
        setHasError(true);
        return;
      }

      // 成功時の処理
      openWindow(tokenResponse.data.service_url, false);
      // ホームに戻る
      navigate('/');
    })();
  });

  if (isUnauthorized) {
    return (
      <ErrorContainer
        message="本ミニアプリの利用には会員登録が必要です。会員登録を行い、再度お試しください。"
        buttonText="会員登録する"
      />
    );
  }

  if (hasError) {
    return (
      <ErrorContainer
        message="エラーが発生しました。もう一度お試しください。"
        buttonText="ホームに戻る"
      />
    );
  }

  return <Loading />;
};

export default RedirectDigiclueplus;
