export const errorCodes = {
  '001': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号・生年月日が正しいかご確認ください。',
    message: ''
  },
  '002': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号・生年月日が正しいかご確認ください。',
    message: ''
  },
  '003': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号・生年月日が正しいかご確認ください。',
    message: ''
  },
  '004': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号・生年月日が正しいかご確認ください。',
    message: ''
  },
  '005': {
    desc: '入力内容に誤りがあります。\nカード番号・PIN番号・生年月日が正しいかご確認ください。',
    message: ''
  },
  '006': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[006]'
  },
  '007': {
    desc: '入力内容の誤りが複数回行われたため、一時的にロックされました。しばらく時間をおいてから登録をお願いします。',
    message: '認証に失敗しました。[007]'
  },
  '008': {
    desc: '正しいカード番号を入力してください。',
    message: ''
  },
  '009': {
    desc: '正しいカード番号を入力してください。',
    message: ''
  },
  '010': {
    desc: '正しいカード番号を入力してください。',
    message: ''
  },
  '011': {
    desc: '入力内容の誤りが複数回行われたため、一時的にロックされました。しばらく時間をおいてから登録をお願いします。',
    message: '認証に失敗しました。[011]'
  },
  '012': {
    desc: 'カードの新規発行ができませんでした。しばらく時間をおいてから登録をお願いします。',
    message: '登録に失敗しました。[012]'
  },
  '014': {
    desc: 'カードの新規発行ができませんでした。',
    message: ''
  },
  '015': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[015]'
  },
  '016': {
    desc: '現在お持ちのカード（デジタルカード）ではカードの再発行はできません。',
    message: ''
  },
  '017': {
    desc: '予期しないエラーが発生しました。再度登録をお試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: '登録に失敗しました。[017]'
  },
  '018': {
    desc: '入力したカード番号は登録できません。',
    message: ''
  },
  '030': {
    desc: '23:00～7:00は、保有ポイントの確認及び、ポイントチャージの機能をご利用いただけません。',
    message: ''
  },
  '031': {
    desc: 'チャージに必要なポイント数が不足しています。',
    message: ''
  },
  '032': {
    desc: 'エラーによりポイントチャージできませんでした。時間をおいて再度お試し下さい。',
    message: ''
  },
  '033': {
    desc: 'チャージに必要なポイント数が不足しています。',
    message: ''
  },
  '034': {
    desc: 'エラーによりポイントチャージできませんでした。時間をおいて再度お試し下さい。',
    message: ''
  },
  '035': {
    desc: 'エラーによりポイントチャージできませんでした。時間をおいて再度お試しください。\n繰り返し発生する場合には、店舗サービスカウンターまでお問い合わせください。',
    message: 'ポイントチャージが失敗しました。[035]'
  },
  '036': {
    desc: 'エラーによりポイントチャージが失敗しました。\nポイント消失の可能性があるため、店舗サービスカウンターかマキヤプリカ専用ダイヤル\n0120-27-9600までお問い合わせください。',
    message: 'ポイントチャージが失敗しました。[036]'
  },
  '037': {
    desc: 'エラーによりポイントチャージが正しく完了していない可能性がございます。\nポイント、マキヤプリカ残高をご確認ください。',
    message: 'エラーが発生しました。[037]'
  },
  '038': {
    desc: '店舗レジにてカード使用中のためポイントチャージに失敗しました。',
    message: ''
  },
  // 上記エラーに該当しない全てに共通する想定外エラー
  unexpected: {
    desc: '予期しないエラーが発生しました。繰り返し発生する場合は、店舗サービスカウンターまでお問い合わせください。',
    message: ''
  }
};
