import React, { useState } from 'react';
import { Stack, Card, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { API_FAILED, API_SUCCEEDED } from 'lib/constants';
import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { getCurrentPoints, patchPrepaidChargeConfirm } from 'api';
import AlertMessage from 'component/AlertMessage';
import TextInput from 'component/TextInput';
import { LoadingButton, SubmitButton } from 'component/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorCodes } from 'lib/errorCodes';

type FormValuesType = {
  changePoint: string;
};

const CardSection: React.FC<{
  title: string;
  titleSecondaryColor?: boolean;
}> = ({ title, titleSecondaryColor, children }) => (
  <Card className="p-4 section-cont--rounded-2">
    <Card.Body className="p-0">
      <Card.Title
        className={titleSecondaryColor ? 'text--secondary-light' : ''}
      >
        {title}
      </Card.Title>
      {children}
    </Card.Body>
  </Card>
);

const validationSchema = yup.object().shape({
  changePoint: yup
    .number()
    // NOTE: 空白のままだとNaNでエラーするため変換する
    .transform((value) => {
      const parseNumber = Number(value);
      return Number.isNaN(parseNumber) ? 0 : parseNumber;
    })
    .integer('整数を入力してください。')
    .min(1, '1ポイント以上で入力してください。')
});

const SERVICE_UNAVAILABLE =
  '現在保有ポイントの確認及びポイントチャージをご利用いただけません。\nなお、23:00～7:00の間はメンテナンスを行っております。';

/**
 * プリカチャージ ポイントチャージ入力フォーム画面
 * */
const PrepaidChargeInput: React.FC = () => {
  PageTitle('保有ポイント');
  const navigate = useNavigate();
  const [isGlobalErrorDisplay, setIsGlobalErrorDisplay] = useState(false);
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError
  } = useForm<FormValuesType>({
    mode: 'onBlur',
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      changePoint: state?.changePoint ?? ''
    }
  });

  // CS-Dからポイント残高取得APIの非同期実行
  const [{ loading: isMutating, value: getPointResult }, getPoint] =
    useAsyncFn(async () => {
      const res = await getCurrentPoints();

      // NOTE: APIの利用時間をミニアプリのLBで制御している関係で、resにresponse dataがない可能性があるので、undefined許容
      if (res?.status === API_SUCCEEDED) {
        // 正常系の場合
        setIsGlobalErrorDisplay(false);
        return { success: true, total_point: res.data.total_point };
      }

      // エラーの場合
      // サービス時間外のエラーメッセージ表示をする
      // NOTE: サービス時間外の場合、夜間アクセス制御に伴う特定URLアクセスでのエラー制御により、
      // レスポンスを正常に受け取れないので sendRequest 関数からfalseが返却された時に表示するようにする
      if (!res) {
        setIsGlobalErrorDisplay(true);
        return { success: false };
      }

      // アプリ共通の想定外エラー
      setError('changePoint', {
        message: errorCodes['unexpected'].desc
      });
    }, []);

  // プリペイドチャージ確認APIの非同期実行
  const [{ loading: isProcessing }, prepaidChargeConfirm] = useAsyncFn(
    async (data) =>
      await patchPrepaidChargeConfirm(Number(data.changePoint ?? 0)),
    []
  );

  const onSubmit = handleSubmit(async (data) => {
    const res = await prepaidChargeConfirm(data);
    const apiFailFlag = res && res?.status === API_FAILED;

    if (res && res.status === API_SUCCEEDED) {
      // エラーしなかった場合は、確認画面へ遷移
      navigate('/prepaid-charge/confirm', {
        state: {
          totalPoint: getPointResult?.total_point,
          changePoint: res.data.deposit_amount
        }
      });
      return;
    }

    // 400で返却された場合は、返却されたエラーメッセージを設定
    if (apiFailFlag && res.http_status === 400) {
      setError('changePoint', {
        type: res.error_code === '031' ? res.error_code : 'custom',
        // NOTE: 400でエラーコードが存在すケースは、残高不足エラーのみなので、
        // エラーコードがない場合はエラーメッセージをそのまま設定する
        message:
          res.error_code === '031'
            ? errorCodes[res.error_code].desc
            : res?.errors[0]
      });
      return;
    }

    // ポイント残高取得残高APIを再実行してエラーメッセージを表示
    // NOTE: プリペイドチャージ確認APIは、夜間アクセス制御に伴う特定URLアクセスでのエラー制御の対象外なので、
    // HTTP STATUSを確認
    if (res && res.http_status === 503) {
      getPoint();
      return;
    }

    // その他エラーを表すエラーコードの場合は、エラーコード一覧からメッセージを取得
    if (apiFailFlag && res.error_code === '032') {
      setError('changePoint', {
        type: res.error_code,
        message: errorCodes[res.error_code].desc
      });
      return;
    }

    // アプリ共通の想定外エラー
    setError('changePoint', {
      message: errorCodes['unexpected'].desc
    });
  });

  useEffectOnce(() => {
    // 初期表示時、ポイント残高取得APIを実行
    getPoint();

    // 初期表示時、stateにエラーメッセージがある場合はそれを表示
    if (state?.errorMessage) {
      setError('changePoint', {
        type: state.errorCode,
        message: state.errorMessage
      });
    }
  });

  return (
    <>
      {/* ポイント残高取得できない場合はポイント交換可能時間外エラーの表示 */}
      {isGlobalErrorDisplay && (
        <AlertMessage type="error" message={SERVICE_UNAVAILABLE} />
      )}
      {Object.values(errors).map((error, ind) => {
        return (
          <AlertMessage
            type="error"
            message={error.message as string}
            key={`error_${ind}`}
            code={
              // エラーコード表示の時のみtypeを利用するように修正
              typeof error.type === 'string' &&
              Object.keys(errorCodes).includes(error.type)
                ? error.type
                : undefined
            }
          />
        );
      })}
      <FullHeightLayout className="px-4">
        <Stack as="form" gap={4} onSubmit={onSubmit}>
          <CardSection title="保有ポイント" titleSecondaryColor>
            {/* NOTE: p要素だとconsoleで怒られるのでdivへ変換 */}
            <Card.Text as="div" className="text-center">
              <span className="fw-bold text--big">
                {isMutating ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  getPointResult?.total_point ?? '---'
                )}
              </span>
              <span className="fw-bold fs-4 text--secondary-light m-2">P</span>
            </Card.Text>
            <Card.Text className="text--secondary-light">
              保有ポイントが500Pを超えると、お会計時レジにて自動で500円分のお買い物券と交換されます。
            </Card.Text>
          </CardSection>
          <CardSection title="ポイントチャージ">
            <Stack direction="horizontal">
              <TextInput
                type="tel"
                className="w-100"
                placeholder="ポイント数を入力してください"
                {...register('changePoint')}
              />
              <span className="fw-bold fs-4 text--secondary-light m-2">P</span>
            </Stack>
            <Card.Text className="text--secondary-light m-0">
              ポイントを使ってマキヤプリカ残高にチャージできます(1ポイント＝1円、チャージは1ポイント単位)
            </Card.Text>
            <Card.Text className="text--secondary-light mt-3 mb-0">
              ※チャージ後の取消はできません
            </Card.Text>
            <Card.Text className="text--secondary-light">
              ※店舗レジにてカード使用中はポイントチャージをご利用いただけません
            </Card.Text>
            <Stack>
              {isProcessing ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  text="確認する"
                  className="w-100 js-edit"
                  disabled={!getPointResult?.success || !isValid}
                />
              )}
            </Stack>
          </CardSection>
        </Stack>
      </FullHeightLayout>
    </>
  );
};
export default PrepaidChargeInput;
